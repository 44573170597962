<!-- 查看水电余额列表 -->
<template>
  <listBalance
    :listBalanceEndpoint = listBalanceEndpoint
    :exportBalanceEndpoint = exportBalanceEndpoint
    :exportTransactionEndpoint = exportTransactionEndpoint
    :balanceDetailRouteName = balanceDetailRouteName
    :exportFilenamePrefix = exportFilenamePrefix
    :viewDetailPermission = viewDetailPermission
    :exportFileType = exportFileType
  />
</template>

<script>
import { FacilityActions } from '../../utils/actions';
import listBalance from "./components/listBalance";

export default {
  name: "utilityBalance",
  components: {
    listBalance,
  },
  data() {
    return {
      listBalanceEndpoint: this.$endpoints.FINANCE_LIST_UTILITY_BALANCE,
      exportBalanceEndpoint: this.$endpoints.FINANCE_EXPORT_UTILITY_CURRENT_BALANCE,
      exportTransactionEndpoint: this.$endpoints.FINANCE_EXPORT_UTILITY_BALANCE_TRANSACTION_HISTORY,
      balanceDetailRouteName: "utilityBalanceDetail",
      exportFilenamePrefix: "水电余额",
      viewDetailPermission: FacilityActions.VIEW_UTILITY_BALANCE_DETAIL,
      exportFileType: 2
    };
  },
};
</script>
